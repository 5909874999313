<template>
  <section class="humans" @scroll="handleScroll">
    <div class="humans__title-container" :class="{ 'is-hide': titleIsHide }">
      <h1 class="humans__title" data-splitting>
        Humans
      </h1>
      <h2 class="humans__subtitle" data-splitting>
        A way of life
      </h2>
    </div>
    <div class="humans__photos" >

      <div class="humans__photo">
        <img class="humans__photo__image" src="../assets/photo/humans/1-costa-rica.jpg">
        <h4 class="humans__photo__title">
          <span class="humans__photo__place">Belen — Costa Rica</span>
          The Rodeo
        </h4>
      </div>

      <div class="humans__photo">
        <img class="humans__photo__image" src="../assets/photo/humans/2-iceland.jpg">
        <h4 class="humans__photo__title">
          <span class="humans__photo__place">Skogar — Iceland</span>
          Trekking at Skógafoss
        </h4>
      </div>

      <div class="humans__photo">
        <img class="humans__photo__image" src="../assets/photo/humans/3-new-zealand.jpg">
        <h4 class="humans__photo__title">
          <span class="humans__photo__place">Piha beach — New Zealand</span>
          Surfer at the Lion Rock
        </h4>
      </div>

      <div class="humans__photo">
        <img class="humans__photo__image" src="../assets/photo/humans/4-italy.jpg">
        <h4 class="humans__photo__title">
          <span class="humans__photo__place">Rome — italy</span>
          Homeless in Tor Fiscale
        </h4>
      </div>

      <div class="humans__photo">
        <img class="humans__photo__image" src="../assets/photo/humans/5-nepal.jpg">
        <h4 class="humans__photo__title">
          <span class="humans__photo__place">Katmandu — Nepal</span>
          Children at the Market
        </h4>
      </div>

      <div class="humans__photo">
        <img class="humans__photo__image" src="../assets/photo/humans/6-nepal.jpg">
        <h4 class="humans__photo__title">
          <span class="humans__photo__place">Katmandu — Nepal</span>
          Baby Nuns in Nagi Gumba Temple
        </h4>
      </div>

      <div class="humans__photo">
        <img class="humans__photo__image" src="../assets/photo/humans/7-nepal.jpg">
        <h4 class="humans__photo__title">
          <span class="humans__photo__place">Katmandu — Nepal</span>
          The Dentist
        </h4>
      </div>

      <div class="humans__photo">
        <img class="humans__photo__image" src="../assets/photo/humans/8-nepal.jpg">
        <h4 class="humans__photo__title">
          <span class="humans__photo__place">Bhaktapur — Nepal</span>
          Reconstruction After the Earthquake
        </h4>
      </div>

      <div class="humans__photo humans__photo--vertical">
        <img class="humans__photo__image" src="../assets/photo/humans/9-guatemala.jpg">
        <h4 class="humans__photo__title">
          <span class="humans__photo__place">Sant Antonio Palopo — Guatemala</span>
          Farmacia del Pueblo
        </h4>
      </div>

      <div class="humans__photo humans__photo--vertical">
        <img class="humans__photo__image" src="../assets/photo/humans/10-china.jpg">
        <h4 class="humans__photo__title">
          <span class="humans__photo__place">Pingyao — China</span>
          Old Woman
        </h4>
      </div>

      <div class="humans__photo">
        <img class="humans__photo__image" src="../assets/photo/humans/11-china.jpg">
        <h4 class="humans__photo__title">
          <span class="humans__photo__place">Guilin — China</span>
          Rice Farmers at the Li River
        </h4>
      </div>

      <div class="humans__photo">
        <img class="humans__photo__image" src="../assets/photo/humans/12-filippine.jpg">
        <h4 class="humans__photo__title">
          <span class="humans__photo__place">Cebu island — Filippine</span>
          Washing at the Kawasan River
        </h4>
      </div>

      <div class="humans__photo">
        <img class="humans__photo__image" src="../assets/photo/humans/13-faer-oer.jpg">
        <h4 class="humans__photo__title">
          <span class="humans__photo__place">Mykines — Fær Øer</span>
          Children at Mykines Village
        </h4>
      </div>

      <div class="humans__photo">
        <img class="humans__photo__image" src="../assets/photo/humans/14-argentina.jpg">
        <h4 class="humans__photo__title">
          <span class="humans__photo__place">Governator Gregores — Argentina</span>
          The Gaucho
        </h4>
      </div>

      <div class="humans__photo">
        <img class="humans__photo__image" src="../assets/photo/humans/15-guatemala.jpg">
        <h4 class="humans__photo__title">
          <span class="humans__photo__place">Santiago Atitlan - Guatemala</span>
          The Sunday Mass
        </h4>
      </div>

    </div>
  </section>
</template>

<script>
// import {preloadImages} from "@/utils"

import {preloadImages} from "@/utils"

export default {
  name: 'Humans',
  data() {
    return {
      titleIsHide: false
    }
  },
  mounted() {
    this.$splitting();
    this.startAnimation();
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading
    }
  },
  methods: {
    handleScroll: function(el) {
      this.titleIsHide = el.target.scrollTop > (el.target.clientHeight / 2);
    },
    startPrelaodImages() {
      preloadImages('.humans__photo__image').then(() => {
        this.startAnimation()
      })
    },
    startTitleAnimation() {
      document.querySelector('.humans__title').classList.add('is-enter')
    },
    startSubtitleAnimation() {
      document.querySelector('.humans__subtitle').classList.add('is-enter')
    },
    startPhotosAnimation() {
      document.querySelector('.humans__photos').classList.add('is-enter')
    },
    startAnimation() {
      setTimeout(() => this.startTitleAnimation(), 500)
      setTimeout(() => this.startSubtitleAnimation(), 800)
      setTimeout(() => this.startPhotosAnimation(), 1500)
    },
  },
  watch: {
    $route() {
      this.routeSlug = this.$route.params.slug
    }
  },
}
</script>

<style lang="scss">
@import '@/scss/utils.scss';

.humans {
  height: 100vh;
  overflow: scroll;
}

.humans__title-container {
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translateY(-50%);
  transition: all .8s ease-in-out;

  @media (orientation: portrait) {
    height: 90vh;
    position: relative;
  }

  &.is-hide {
    opacity: .1;
  }
}

.humans__title {
  @include title;
  transition: all 1s ease-in-out;

  @media (orientation: portrait) {
    @include title(medium-portrait);
    font-size: 20vw;
    line-height: 20vw;
  }

  .word,
  .char {
    display: inline-block;
  }

  .char {
    opacity: 0;
    transform: translateX(20px);
  }

  &.is-enter .char {
    @keyframes title-enter {
      from {
        opacity: 0;
        transform: translateX(20px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
    animation: title-enter 2s cubic-bezier(.2, 0, .1, 1) both;
    animation-delay: calc(.1s * var(--char-index));
  }
}

.humans__subtitle {
  @include text(large);
  transition: all 1s ease-in-out;

  @media (orientation: portrait) {
    font-size: 15px;
    line-height: 25px;
  }

  .word,
  .char {
    display: inline-block;
  }

  .char {
    opacity: 0;
    transform: translateX(20px);
  }

  &.is-enter .char {
    @keyframes title-enter {
      from {
        opacity: 0;
        transform: translateX(20px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
    animation: title-enter 3s cubic-bezier(.2, 0, .1, 1) both;
    animation-delay: calc(.1s * var(--char-index));
  }
}

.humans__photos {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 90vh 15vw 0 15vw;
  position: relative;
  z-index: 1;
  margin-bottom: 10vh;
  transition: top 1s ease-in-out;
  top: 10vh;

  &.is-enter {
    top: 0;
  }

  @media (orientation: portrait) {
    padding: 0 30px 0 30px;
  }
}

.humans__photo {
  position: relative;
  margin-bottom: 10vh;

  @media (orientation: portrait) {
    margin-bottom: 30px;
  }
}

.humans__photo__image {
  display: block;

  .humans__photo--vertical & {
    max-height: 90vh;
    margin: 0 auto;
  }
}

.humans__photo__title {
  @include title(smaller);
  position: absolute;
  bottom: 15px;
  left: 50px;
  opacity: 0;
  transition: all 0.8s ease-in-out;
  text-shadow: 0 2px 5px rgba(0,0,0,.4);

  .humans__photo:hover & {
    opacity: 1;
    bottom: 35px;
  }

  @media (orientation: portrait) {
    opacity: 1;
    position: static;
    padding-top: 10px;
    padding-bottom: 20px;
    @include title(small);
  }
}

.humans__photo__place {
  @include text(medium);
  margin-bottom: 10px;
  display: block;
  position: relative;

  @media (orientation: portrait) {
    margin-bottom: 2px;
  }
}

</style>